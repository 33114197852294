<template>
  <div>
    <b-overlay
      opacity="0.8"
      variant="transparent"
      blur="0.8rem"
      spinner-variant="primary"
      :show="loading"
    >
      <template #overlay>
        <div class="text-center">
          <feather-icon
            icon="ClockIcon"
            size="40"
            variant="primary"
            class="fade"
          />
          <p
            style="font-weight: bold; font-size: 1.2rem"
            class="text-primary"
          >
            POR FAVOR, ESPERE...
          </p>
        </div>
      </template>
      <b-row>
        <b-col cols="1" />
        <b-col
          v-if="!isMobile"
          cols="3"
        >
          <b-form-group>
            <label>Estado:</label>
            <v-select
              v-model="etapa"
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          cols="2"
        >
          <b-form-group>
            <label>Fecha Inicio:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="example-input"
                v-model="fechaInicio"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaInicio"
                  show-decade-nav
                  button-only
                  size="sm"
                  right
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          cols="2"
        >
          <b-form-group>
            <label>Fecha Fin:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="fecha-fin"
                v-model="fechaFin"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaFin"
                  show-decade-nav
                  button-only
                  size="sm"
                  right
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          style="margin-top: 21px"
          cols="2"
        >
          <table>
            <tr>
              <td>
                <div style="margin-left: -20px">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.v-primary
                    title="Buscar"
                    variant="primary"
                    class="btn-icon"
                    @click="loadReport()"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </div>
              </td>
              <td>
                <div style="width: 180px">
                  <modal-datos-entrega
                    :seleccionadas="seleccionadas"
                    @actualizar-datos="limpiarActualizar"
                  />
                </div>
              </td>
              <td v-if="usuario.company.enabledReport">
                <div style="margin-left: 20px">
                  <reporte-general
                    :rows="rowsReporte"
                    :columns="columnsReporte"
                    :titulo-descarga="tituloDescarga"
                    :subtitulo="subtitulo"
                    :titulo-secundario="tituloSecundario"
                    :titulo-principal="tituloPrincipal"
                  />
                </div>
              </td>
              <td v-if="usuario.company.enabledReport">
                <reporte-luminarias-instaladas
                  :fecha-inicio="fechaInicio"
                  :fecha-fin="fechaFin"
                  :luminarias-recolectadas="filteredRows"
                />
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-input
            v-model="buscarLuminaria"
            placeholder="Buscar luminaria..."
          />
          <hr>
        </b-col>
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="filteredRows"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :select-options="selectOptions()"
            style-class="vgt-table condensed"
            @on-row-dblclick="showDetalle"
            @on-selected-rows-change="selectionChanged"
            @on-select-all="onSelectAll"
          >
            <!-- detalles -->
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'fechaCreacion'">
                {{ formatFecha(props.row.fechaCreacion) }}
              </span>
              <span v-else-if="props.column.field === 'serieLampara'">
                {{ (props.row.seguimiento[0].datosPunto.serieLampara) }}
              </span>
              <span v-else-if="props.column.field === 'estado'">
                {{ getEstados(props.row.estado) }}
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Mostrar
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '15', '20']"
                    class="mx-1"
                    @input="value => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> de {{ props.total }} registros </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="value => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-overlay>
    <div>
      <b-modal
        id="modal-1"
        v-model="verModalDetalle"
        title="Detalle de Entrega de Luminaria"
        ok-only
        hide-footer
        size="lg"
        ok-title="Accept"
        no-close-on-backdrop
      >
        <modal-detalle-seguimiento :ticket="ticket" />
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { isMobile } from '@/utils/funciones'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { findEntregaLuminaria, tiposEstados } from '@/utils/entregaLuminarias/entregaLuminariasUtils'
import { calcularFecha, obtenerFechaInicioFin, sumarDiasFecha } from '@/utils/fechas'
import ModalDetalleSeguimiento from '@/components/entregaLuminarias/modalDetalleSeguimiento.vue'
import ModalDatosEntrega from '@/components/entregaLuminarias/modalDatosEntrega.vue'
import { mensajeError } from '@/utils/mensajes'
import {
  getRows, getColumns,
  tituloPrincipal, tituloSecundario, subtitulo, tituloDescarga,
} from '@/utils/reportes/entregaLuminarias/luminariasRecolectadas'
import ReporteGeneral from '@/components/reportes/reporteGeneral.vue'
import ReporteLuminariasInstaladas from '@/utils/reportes/levantamientoInicial/reporteLuminariasInstaladas.vue'

export default {
  components: {
    ReporteLuminariasInstaladas,
    ReporteGeneral,
    ModalDatosEntrega,
    ModalDetalleSeguimiento,
    BRow,
    BCol,
    vSelect,
    BButton,
    BOverlay,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BFormDatepicker,
    BInputGroupAppend,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      verModalDetalle: false,
      verModalAsignar: false,
      buscarLuminaria: '',
      fechaInicio: '',
      fechaFin: '',
      etapa: '',
      seleccionadas: [],
      ticket: null,
      pageLength: 15,
      rows: [],
      option: [
        { title: 'Todos', value: 'TODOS' },
        { title: 'Recolectadas', value: 'RECOLECTADA' },
        { title: 'Pendientes', value: 'PENDIENTE' },
        { title: 'Finalizadas', value: 'FINALIZADA' },
      ],
      columns: [
        {
          label: 'Ticket N.',
          field: 'ticket',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
          type: 'number',
        },
        {
          label: 'Poste',
          field: 'puntoIluminacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Serie Lámpara',
          field: 'serieLampara',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Técnico Encargado',
          field: 'tecnicoEncargado.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha de Sustitución',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      estados: '',
      columnsReporte: [],
      rowsReporte: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    tituloPrincipal() {
      return tituloPrincipal
    },
    tituloSecundario() {
      return tituloSecundario
    },
    subtitulo() {
      return subtitulo
    },
    tituloDescarga() {
      return tituloDescarga
    },
    isMobile() {
      return isMobile()
    },
    filteredRows() {
      if (!this.buscarLuminaria) {
        return this.rows
      }
      return this.rows.filter(row => {
        const searchTerm = this.buscarLuminaria.toLowerCase()
        return (
          String(row.ticket).toLowerCase().includes(searchTerm)
            || (row.puntoIluminacion && row.puntoIluminacion.toLowerCase().includes(searchTerm))
            || (row.tecnicoEncargado.nombre && row.tecnicoEncargado.nombre.toLowerCase().includes(searchTerm))
        )
      })
    },
  },
  watch: {
    buscarLuminaria() {
      this.applySelections()
    },
  },
  async created() {
    this.rows = []
    const fechas = obtenerFechaInicioFin()
    this.fechaFin = fechas.fechaFin
    this.fechaInicio = fechas.fechaInicio
    await this.loadReport()
  },
  methods: {
    applySelections() {
      this.$nextTick(() => {
        this.filteredRows.forEach(row => {
          // eslint-disable-next-line no-param-reassign
          row.vgtSelected = this.seleccionadas.some(
            selectedRow => selectedRow.id === row.id,
          )
        })
      })
    },
    limpiarActualizar() {
      this.loadReport()
      this.seleccionadas = []
    },
    generarEntrega() {
      if (this.seleccionadas.length > 0) {
        this.verModalAsignar = true
      } else {
        mensajeError('Debe seleccionar una luminaria para entrega')
      }
    },
    estadoSeguimiento(estado) {
      return this.estados[estado]?.nombre || estado
    },
    async loadReport() {
      this.loading = true
      try {
        const fechaInicio = new Date(this.fechaInicio)
        const fechaFin = sumarDiasFecha(new Date(this.fechaFin), 1)
        const filter = {
          where: {
            and: [
              { companyId: this.usuario.idCompany },
              { fechaCreacion: { gte: fechaInicio.toISOString() } },
              { fechaCreacion: { lte: fechaFin.toISOString() } },
            ],
          },
          order: ['fechaCreacion DESC'],
        }

        if (this.etapa && this.etapa.value !== '') {
          filter.where.and.push({ estado: this.etapa.value })
        }
        this.rows = await findEntregaLuminaria(filter)
        this.rowsReporte = getRows([...this.rows])
        this.columnsReporte = getColumns()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    selectOptions() {
      return {
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }
    },
    selectionChanged(params) {
      this.seleccionadas = params.selectedRows
      this.applySelections()
    },
    onSelectAll(params) {
      this.seleccionadas = params.selectedRows
      this.applySelections()
    },
    async showDetalle(params) {
      const ticket = params.row
      if (ticket) {
        this.ticket = ticket
        this.verModalDetalle = true
      }
    },
    formatFecha(row) {
      return calcularFecha(row)
    },
    getEstados(row) {
      return tiposEstados[row]
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
@keyframes fade {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.fade {
  animation: fade 1s ease-in-out infinite;
}
</style>
